import styles from "components/layout/store/products/GridProducts.module.scss";
import ProductListCard from "components/cards/ProductListCard";
import classNames from "classnames";


const GridProducts = (props) => {
    const {
        products, onProductClick, imageRatio, showPromotionDaysLeft,
        productIdToDescription, isAddToPouchVisible, onPouchAdd, useBestCouponDiscount,
        theme, textPaddingType
    } = props;

    const column_count = props.columnCount || 3;

    return (
        <div className={classNames(styles.productsWrapper, styles[`column_${column_count}`])}>
            {
                products.map((e, index) => {
                    return <ProductListCard
                        onCloseOptionSelectorModal={() => onPouchAdd(e)}
                        key={`product-${e.id}`}
                        product={e}
                        onClick={() => onProductClick(e, index)}
                        imageRatio={imageRatio}
                        showPromotionDaysLeft={showPromotionDaysLeft}
                        description={productIdToDescription && productIdToDescription[e.id]}
                        hasAddToPouchButton={isAddToPouchVisible}
                        useBestCouponDiscount={useBestCouponDiscount}
                        theme={theme}
                        textPaddingType={textPaddingType}
                    />
                })
            }
        </div>
    )
};

export default GridProducts;
